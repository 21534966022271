module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e93a163f9ec23d6daa25c734d25dc1c"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/nizzle1994/Developments/WebStorm/ActionLab/Pip-KA/node_modules/gatsby-remark-images","id":"a319792d-78d5-5323-b429-e12ae56a0a87","name":"gatsby-remark-images","version":"6.16.0","modulePath":"/Users/nizzle1994/Developments/WebStorm/ActionLab/Pip-KA/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":960,"linkImagesToOriginal":true,"showCaptions":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/nizzle1994/Developments/WebStorm/ActionLab/Pip-KA/packages/web","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"linkImagesToOriginal":true,"showCaptions":true},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
