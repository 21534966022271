import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, doc, writeBatch, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { actions } from '../profileSlice';
import type { state } from 'app';
import { getUid } from './helper';

/**
 * Start a survey or optionally mark start of a stage
 */
export const startSurvey = createAsyncThunk(
  'survey/start',
  async ({ surveyId, currentStage }: { surveyId: string; currentStage?: 'survey' | 'followup' }) => {
    const firestore = getFirestore();
    const uid = getUid();
    const batch = writeBatch(firestore);

    if (currentStage) {
      batch.update(doc(firestore, 'users', uid), { currentStage });
    }

    const data: state.Survey = { _totalRequired: 0, _totalOptional: 0,_completedOptional:0, _completedRequired: 0 ,_step: 0 };
    batch.set(doc(firestore, `users/${uid}/surveys`, surveyId), data);
    await batch.commit();

    return {
      id: surveyId,
      currentStage,
      data,
    };
  },
);

/**
 * Update survey
 */
export const updateSurvey = createAsyncThunk(
  'profile/updateSurvey',
  async ({ id, data }: { id: string; data: Partial<state.Survey> }, thunkApi) => {
    const firestore = getFirestore();
    const uid = getUid();

    await updateDoc(doc(firestore, `users/${uid}/surveys`, id), data);
    return {
      id,
      data,
    };
  },
);

/**
 * Mark baseline or followup survey completed
 */

export const markCompleted = createAsyncThunk(
  'profile/markCompleted',
  async ({ scope }: { scope: 'survey' | 'topic' | 'followup' }, thunkApi) => {
    thunkApi.dispatch(actions.markLocalCompleted({ scope }));

    const functions = getFunctions();
    await httpsCallable(
      functions,
      'completed',
    )({
      scope,
    });
  },
);
